.site-header {
	background: #e0ecde;
	border-radius: 10px 10px 4px 4px;
	box-shadow: 1px 1px 60px #0000003b;
	margin-bottom: 10px;
	padding: 5px 10px;

	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.page-title {
		text-align: center;
		font-weight: bold;
		font-size: 1.1rem;
		padding: 4px 0;
		margin: 0;
		min-width-removed: 250px;
		text-shadow: 1px 1px 1px #fff, 2px 2px 2px #aaa, 3px 3px 3px #6b6b6b;
	}
	.header-icon-button {
		font-size: 1rem;
		padding: 6px 8px;
		line-height: 0;
		margin: -3x;
		padding: 9px 11px;
	}
	.MuiButton-root {
		min-width: auto;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
