:root {
	--text-selection: rgb(164, 219, 255);
	--body-background: #e1e8e0;
	--body-color: #325171;
	--scrollbar-color-back: #a8d4c7;
	--scrollbar-color-grabber: #d8f2d3;
	--link-color: #2c6975;
	--link-active: #4f8376;
	--link-hover: #68b2a0;
	--button-simple-border: #ced9cc;
	--button-simple-border-focus: #7eb09f;
	--button-simple-color-hover: #738c52;
	--button-simple-hover-back: whitesmoke;
	--button-simple-active-color: #33463e;
	--button-simple-active-border: #33463e;
	--button-simple-active-back: #f3f1ea;
	--button-simple-white-back: white;
	--button-simple-white-hover-back: whitesmoke;
	--button-simple-white-shadow: silver;
	--button-simple-white-active-back: #f3f1ea;
	--button-simple-white-active-back1: #e0ecde;
	--button-simple-white-active-shadow: silver;
	--button-simple-subtle-color: rgba(0, 0, 0, 0.87);
	--button-simple-subtle-hover-back: rgba(0, 0, 0, 0.04);
	--button-simple-subtle-active-back: rgba(0, 0, 0, 0.1);
	--button-outlined-border: rgba(0, 0, 0, 0.24);
	--button-outlined-color: rgb(33, 150, 243);
	--button-contained-color: rgb(255, 255, 255);
	--button-outlined-back: rgb(33, 150, 243);
	--button-outlined-shadow1: rgba(0, 0, 0, 0.2);
	--button-outlined-shadow2: rgba(0, 0, 0, 0.14);
	--button-outlined-shadow3: rgba(0, 0, 0, 0.12);
	--button-outlined-overlay-back: rgb(255, 255, 255);
	--button-outlined-overlay-back: rgb(255, 255, 255);
	--button-outlined-disabled-color: rgba(0, 0, 0, 0.38);
	--button-outlined-disabled-back: rgba(0, 0, 0, 0.12);
	--search-input-border: #1abc9c;
	--search-input-color: #3251716b;
	--search-input-icon-back: rgba(192, 192, 192, 0.5);
	--search-input-button-color: #325171;
	--search-input-text-back1: rgba(255, 255, 255, 0);
	--search-input-text-back2: #1abc9c;
	--sticky-inline-header-shadow: silver;
	--page-inline-header-back: #f5f5f5;
	--page-inline-header-shadow: #dfdfdf;
	--out-breadcrumbs-back: #f1f2ed;
	--breadcrumb-item-back: #f3f1ea;
	--breadcrumb-item-border: #e3ebdf;
	--breadcrumb-item-hover-back: #f5f5f5;
	--breadcrumb-item-hover-shadow: #7ac197;
	--breadcrumb-item-focus-back: #f5f5f5;
	--breadcrumb-item-focus-shadow: #7ac197;
	--breadcrumb-item-separator-color: gray;
	--content-layout-back1: #e0ecde;
	--content-layout-back2: #f4f1e9;
	--content-layout-shadow: #0000003b;
	--main-section-back: #f5f5f57d;
	--main-section-shadow: #dfdfdf;
	--main-section-title-back: #f5f5f5;
	--category-button-back: white;
	--category-button-border: #ced9cc;
	--category-button-active-border: #7eb09f;
	--category-button-hover-color: black;
	--category-button-hover-back: whitesmoke;
	--category-button-hover-shadow: silver;
	--category-button-active-back1: #f3f1ea;
	--category-button-active-back2: #e0ecde;
	--item-button-back: white;
	--item-button-border: #ced9cc;
	--item-button-hover-color: black;
	--item-button-focus-border: #7eb09f;
	--item-button-active-back1: #f3f1ea;
	--item-button-active-back2: #e0ecde;
	--item-button-active-shadow: silver;
	--zekr-body-line-color: green;
	--zekr-body-play-buttons-back: #f5f5f5;
	--zekr-body-play-buttons-shadow: #dfdfdf;
	--zekr-body-play-buttons-sticky-shadow: silver;
}
body {
	margin: 0;
	font-family: 'Vazir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Tahoma', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 10pt;
}
input,
textarea,
button {
	font-family: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	// color: #2b463c;
	box-sizing: border-box;

	&:hover {
		border-color: #95ddff;
	}
}
::selection {
	background: var(--text-selection);
}

a {
	text-decoration: none;
	color: var(--link-color);

	&:active {
		color: var(--link-hover);
		border-color: var(--link-hover);
	}
	&:active {
		border-color: var(--link-active);
	}
	&:active > img {
		filter: grayscale(1);
	}
	&:focus {
		outline: 0;
	}
}
a > img.link-image {
	&:hover {
		opacity: 0.7;
		filter: drop-shadow(1px 1px 2px gray);
	}
	&:active {
		opacity: 0.8;
		filter: grayscale(1);
	}
}

html {
	direction: rtl;
	max-width: 1000px;
	margin: 0 auto;

	background: none;
	background-repeat: repeat-x;
	background-color: var(--body-background);

	// // background-image: url('/assets/images/bg-green.png');
	background-repeat: repeat;
	scrollbar-width-disabled: thin;
	scrollbar-color: var(--scrollbar-color-back) var(--scrollbar-color-grabber);
}

body {
	padding: 10px 12px;
	box-sizing: border-box;
	color: var(--body-color);
	background: none;
}

html,
body {
	direction: rtl;
	height: 100%;
	min-height: 100%;
}

#root {
	height: 100%;
	min-height: 100%;
}

.text-align-center {
	text-align: center;
}

.text-align-end {
	text-align: end;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.scrollable {
	overflow-y: auto;
	height: fit-content;
}
.no-wrap {
	white-space: nowrap;
}
.icon-32 {
	width: 32px;
	height: 32px;
	vertical-align: middle;
}
.icon-28 {
	width: 28px;
	height: 28px;
	vertical-align: middle;
}
.icon {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}
.icon-24 {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}
.icon-20 {
	width: 20px;
	height: 20px;
	vertical-align: middle;
}
.icon-16 {
	width: 16px;
	height: 16px;
	vertical-align: middle;
}
.icon-13 {
	width: 13px;
	height: 13px;
	vertical-align: middle;
}
.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}
.no-pointer-events {
	pointer-events: none;
}
.no-margin {
	margin: 0 !important;
}
.p-0 {
	padding: 0;
}
.p-1 {
	padding: 0.25rem;
}
.p-2 {
	padding: 0.5rem;
}
.p-3 {
	padding: 1rem;
}
.p-4 {
	padding: 1.5rem;
}
.p-5 {
	padding: 3rem;
}
.px-0 {
	padding-left: 0;
	padding-right: 0;
}
.px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}
.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.px-3 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.px-4 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.px-5 {
	padding-left: 3rem;
	padding-right: 3rem;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}
.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}
.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.mx-3 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.mx-4 {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}
.mx-5 {
	margin-left: 3rem;
	margin-right: 3rem;
}

.opacity-0 {
	opacity: 0;
}
.opacity-25 {
	opacity: 0.25;
}
.opacity-50 {
	opacity: 0.5;
}
.opacity-75 {
	opacity: 0.75;
}
.opacity-100 {
	opacity: 1;
}

/*
-----------------
BLOCK
*/
.block-container {
	display: table;
	height: 100%;
	width: 100%;

	> div {
		display: table-row;

		> .block-cell {
			display: table-cell;
		}
	}

	.block-stretch-height {
		height: 100%;
	}
}

/*
-----------------
-----------------
*/
.button-simple {
	display: inline-block;

	border-radius: 3px;
	border: solid 1px var(--button-simple-border);
	cursor: pointer;
	padding: 4px 5px;
	background-color: transparent;

	&:focus {
		border-color: var(--button-simple-border-focus);
	}
	&:hover {
		color: var(--button-simple-color-hover);
		border-color: var(--button-simple-border-focus);
		background-color: var(--button-simple-hover-back);
	}
	&:active {
		color: var(--button-simple-active-color);
		border-color: var(--button-simple-active-border);
		background-color: var(--button-simple-active-back);
	}
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all 0.2s ease;

	&.button-sm {
		padding: 2px 3px;
	}
	&.button-white {
		background-color: var(--button-simple-white-back);

		&:hover {
			border-color: var(--button-simple-border-focus);
			background-color: var(--button-simple-white-hover-back);
			box-shadow: 0px 1px 2px var(--button-simple-white-shadow);
		}
		&:active {
			border-color: var(--button-simple-border-focus);
			background-color: var(--button-simple-white-active-back);
			background: linear-gradient(var(--button-simple-white-active-back1), var(--button-simple-white-active-back));
			box-shadow: 0px 1px 2px var(--button-simple-white-active-shadow);
		}
	}
	&.button-subtle {
		border: 0;
		background: none;
		color: var(--button-simple-subtle-color);

		&:hover {
			background-color: var(--button-simple-subtle-hover-back);
		}
		&:active {
			background-color: var(--button-simple-subtle-active-back);
		}
		&:focus:not(:focus-visible) {
			background-color: none;
		}
	}
}

.button-outlined {
	// source: https://codepen.io/finnhvman/pen/zMKagM
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	border: solid 1px;
	border-color: var(--button-outlined-border);
	border-radius: 4px;
	padding: 0 16px;
	min-width: 64px;
	height: 36px;
	vertical-align: middle;
	text-align: center;
	text-overflow: ellipsis;
	text-transform: uppercase;
	//color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
	color: var(--button-outlined-color);
	background-color: transparent;
	font-family: var(--pure-material-font, 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system);
	font-size: 14px;
	font-weight: 500;
	line-height: 34px;
	overflow: hidden;
	outline: none;
	cursor: pointer;
}

.button-outlined::-moz-focus-inner {
	border: none;
}

/* Overlay */
.button-outlined::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: currentColor;
	opacity: 0;
	transition: opacity 0.2s;
}

/* Ripple */
.button-outlined::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	border-radius: 50%;
	padding: 50%;
	width: 32px;
	height: 32px;
	background-color: currentColor;
	opacity: 0;
	transform: translate(-50%, -50%) scale(1);
	transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.button-outlined:hover::before {
	opacity: 0.04;
}

.button-outlined:focus::before {
	opacity: 0.12;
}

.button-outlined:hover:focus::before {
	opacity: 0.16;
}

/* Active */
.button-outlined:active::after {
	opacity: 0.16;
	transform: translate(-50%, -50%) scale(0);
	transition: transform 0s;
}

/* Disabled */
.button-outlined:disabled {
	color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
	background-color: transparent;
	cursor: initial;
}

.button-outlined:disabled::before {
	opacity: 0;
}

.button-outlined:disabled::after {
	opacity: 0;
}
// -----------------------------------
// -----------------------------------

.button-contained {
	// source: https://codepen.io/finnhvman/pen/zMKagM
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	border: none;
	border-radius: 4px;
	padding: 0 16px;
	min-width: 64px;
	height: 36px;
	vertical-align: middle;
	text-align: center;
	text-overflow: ellipsis;
	text-transform: uppercase;
	//color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
	color: var(--button-contained-color);
	//background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
	background-color: var(--button-outlined-back);
	box-shadow: 0 3px 1px -2px var(--button-outlined-shadow1), 0 2px 2px 0 var(--button-outlined-shadow2),
		0 1px 5px 0 var(--button-outlined-shadow3);
	font-family: var(--pure-material-font, 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system);
	font-size: 14px;
	font-weight: 500;
	line-height: 36px;
	overflow: hidden;
	outline: none;
	cursor: pointer;
	transition: box-shadow 0.2s;
}

.button-contained::-moz-focus-inner {
	border: none;
}

/* Overlay */
.button-contained::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--button-outlined-overlay-back);
	// background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
	opacity: 0;
	transition: opacity 0.2s;
}

/* Ripple */
.button-contained::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	border-radius: 50%;
	padding: 50%;
	width: 32px; /* Safari */
	height: 32px; /* Safari */
	background-color: var(--button-outlined-overlay-back);
	// background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
	opacity: 0;
	transform: translate(-50%, -50%) scale(1);
	transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.button-contained:hover,
.button-contained:focus {
	box-shadow: 0 2px 4px -1px var(--button-outlined-shadow1), 0 4px 5px 0 var(--button-outlined-shadow2),
		0 1px 10px 0 var(--button-outlined-shadow3);
}

.button-contained:hover::before {
	opacity: 0.08;
}

.button-contained:focus::before {
	opacity: 0.24;
}

.button-contained:hover:focus::before {
	opacity: 0.3;
}

/* Active */
.button-contained:active {
	box-shadow: 0 5px 5px -3px var(--button-outlined-shadow1), 0 8px 10px 1px var(--button-outlined-shadow2),
		0 3px 14px 2px var(--button-outlined-shadow3);
}

.button-contained:active::after {
	opacity: 0.32;
	transform: translate(-50%, -50%) scale(0);
	transition: transform 0s;
}

/* Disabled */
.button-contained:disabled {
	color: var(--button-outlined-disabled-color);
	background-color: var(--button-outlined-disabled-back);
	box-shadow: none;
	cursor: initial;
}

.button-contained:disabled::before {
	opacity: 0;
}

.button-contained:disabled::after {
	opacity: 0;
}
/*
-----------------
-----------------
*/
.search-input {
	// this includes Input and a Button
	width: 100%;
	border-bottom: solid 1px var(--search-input-border);
	transition: all 0.3s ease-in-out;
	color: var(--search-input-color) !important;

	.button-icon {
		border-radius: 50% !important;
		&:focus,
		&:hover {
			opacity: 0.9;
			background-color: var(--search-input-icon-back);
		}
	}
	input,
	button {
		border: none;
		background-color: transparent;
		opacity: 0.7;
		&:focus,
		&:valid {
			box-shadow: none;
			outline: none;
			background-color: transparent;
			color: var(--search-input-button-color) !important;
			opacity: 1;
		}
	}

	input {
		border-radius: 0 !important;
		transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
		background: linear-gradient(to bottom, var(--search-input-text-back1) 95%, var(--search-input-text-back2) 4%);
		background-position: +400px 0;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		&:focus {
			box-shadow: none;
			outline: none;
			background-position: 100% 0;
		}
	}
}

/*
-----------------
-----------------
*/
.sticky-inline-header {
	position: -webkit-sticky;
	position: sticky;
	top: -1px;
	z-index: 1000;
	transition: 0.3s;

	&.position-sticky {
		box-shadow: 0px 3px 4px var(--sticky-inline-header-shadow);
	}
}
.page-inline-header {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--page-inline-header-back);
	box-shadow: 0px 0px 4px var(--page-inline-header-shadow);
	border-radius: 3px;
	padding: 5px 10px;
	min-height: 39px;
	.inline-breadcrumbs {
		order: 1;
	}
	.inline-title {
		order: 2;
		font-weight: bold;
		font-size: 1rem;
		line-height: inherit;
		margin: 0;
	}
	.inline-actions {
		order: 3;
		white-space: nowrap;
	}

	/* Small devices (portrait tablets and large phones, 600px and up) */
	@media screen and (max-width: 600px) {
		justify-content: space-between;

		.inline-breadcrumbs {
			order: 3;
			width: 100%;
			margin-top: 8px;
			margin-bottom: 3px;
			displayx: none;
		}
		.inline-title {
			order: 1;
			font-size: 0.9rem;
		}
		.inline-actions {
			order: 2;
		}
	}
}
.out-breadcrumbs {
	width: 100%;
	margin-top: 3px;
	margin-bottom: 3px;
	padding: 4px 5px;
	border-radius: 3px;
	background-color: var(--out-breadcrumbs-back);
	&::after {
		content: '';
		margin-bottom: 10px;
	}
}

.breadcrumbs-list {
	display: inline-block;
	margin: 1px 5px;
	.breadcrumb-item {
		padding: 2px 9px;
		cursor: pointer;

		background-color: var(--breadcrumb-item-back);
		border: 1px solid var(--breadcrumb-item-border);
		border-radius: 15px;
		&:hover {
			background-color: var(--breadcrumb-item-hover-back);
			box-shadow: 0px 0px 4px var(--breadcrumb-item-hover-shadow);
		}
		&:focus {
			background-color: var(--breadcrumb-item-focus-back);
			box-shadow: 0px 0px 4px var(--breadcrumb-item-focus-shadow);
		}
		img ~ span {
			margin-right: 5px;
			padding: 2px 2px;
		}
		span ~ img {
			margin-right: 5px;
		}
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		transition: all 0.5s ease;
	}

	.breadcrumb-separator {
		color: var(--breadcrumb-item-separator-color);
		padding: 0 5px;
	}
}

.content-layout-row {
	height: 100%;
	.content-layout {
		height: 100%;
		padding: 20px 10px 0;
		background: var(--content-layout-back1);
		background: radial-gradient(var(--content-layout-back2), var(--content-layout-back1));
		border-radius: 4px 4px 10px 10px;
		box-shadow: 1px 1px 60px var(--content-layout-shadow);
	}
	.content-header {
		background: var(--content-layout-back1);
		border-radius: 10px 10px 4px 4px;
		box-shadow: 1px 1px 60px var(--content-layout-shadow);
		margin-bottom: 10px;
		padding: 5px 10px;
	}
}
.main-search-bar {
	.search-input {
		width: 100%;
		max-width: 400px;
	}
}
.main-section {
	background: var(--main-section-back);
	border-radius: 3px;
	padding: 5px 10px;
	margin: 2px 0 10px;
	box-shadow: 0px 5px 4px var(--main-section-shadow);

	.main-section-title {
		background: var(--main-section-title-back);
		border-radius: 3px;
		padding: 5px 10px;
		margin: 2px 0 10px;
		font-size: inherit;
		&.title-with-tools {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.title-tools {
			}
			.search-input {
				width: 100%;
				max-width: 400px;
			}
		}
	}
}

// .button-list {
// 	text-align: justify;
// 	display: -webkit-box;
// 	display: -moz-box;
// 	display: -ms-flexbox;
// 	display: -webkit-flex;
// 	display: flex;
// 	flex-wrap: wrap;

// 	.button-link {
// 		flex-grow: 1;
// 		flex-shrink: 1;
// 		display: inline-block;
// 		margin: 2px 10px;
// 		padding: 5px 17px;
// 		background: white;
// 		border-radius: 7px;
// 		border: solid 1px #ced9cc;
// 		cursor: pointer;
// 		min-width: 100px;
// 		max-width: 200px;
// 		white-space: nowrap;
// 		overflow: hidden;
// 		text-overflow: ellipsis;
// 		margin-right: 0;
// 		// &:first-child {
// 		// 	margin-right: 0;
// 		// }
// 		&.button-link-more {
// 			flex-grow: 0;
// 			margin-right: auto;
// 		}
// 		&:focus {
// 			border-color: #7eb09f;
// 		}
// 		&:hover {
// 			color: black;
// 			border-color: #7eb09f;
// 			background-color: whitesmoke;
// 			box-shadow: 0px 1px 2px silver;
// 		}
// 		&:active {
// 			border-color: #7eb09f;
// 			background-color: #f3f1ea;
// 			background: linear-gradient(#e0ecde, #f3f1ea);
// 			box-shadow: 0px 1px 2px silver;
// 		}
// 		-moz-user-select: none;
// 		-webkit-user-select: none;
// 		-ms-user-select: none;
// 		user-select: none;
// 		transition: all 0.5s ease;
// 	}
// }

.category-list {
	text-align: justify;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-left: -10px;
	margin-right: -10px;
	.category-button {
		flex-grow: 0;
		width: 120px;
		height: 80px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		margin: 2px 5px 10px;
		padding: 15px 4px 1px 4px;
		max-width: 200px;
		background: var(--category-button-back);
		border-radius: 7px;
		border: solid 1px var(--category-button-border);
		cursor: pointer;
		.button-icon {
			display: inline-block;
			margin: 2px;
		}
		.button-text {
			display: inline-block;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&.button-link-more {
			flex-grow: 0;
			margin-right: auto;
		}
		&:focus {
			border-color: var(--category-button-active-border);
		}
		&:hover {
			color: var(--category-button-hover-color);
			border-color: var(--category-button-active-border);
			background-color: var(--category-button-hover-back);
			box-shadow: 0px 1px 2px var(--category-button-hover-shadow);
		}
		&:active {
			border-color: var(--category-button-active-border);
			background-color: var(--category-button-active-back1);
			background: linear-gradient(var(--category-button-active-back2), var(--category-button-active-back1));
			box-shadow: 0px 1px 2px var(--category-button-hover-shadow);
		}
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		transition: all 0.5s ease;
	}
}

.item-list {
	text-align: justify;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	.item-button {
		flex-grow: 1;
		flex-shrink: 1;
		display: inline-block;
		margin: 2px 5px;
		padding: 10px 5px;
		width: 100%;
		max-width: 450px;
		background: var(--item-button-back);
		border-radius: 7px;
		border: solid 1px var(--item-button-border);
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.button-icon {
		}
		.button-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&.item-button-more {
			flex-grow: 0;
			margin-right: auto;
			max-width: 150px;
		}
		&:focus {
			border-color: var(--item-button-focus-border);
		}
		&:hover {
			color: var(--item-button-hover-color);
			border-color: var(--item-button-focus-border);
			background-color: whitesmoke;
			box-shadow: 0px 1px 2px var(--item-button-active-shadow);
		}
		&:active {
			border-color: var(--item-button-focus-border);
			background-color: var(--item-button-active-back1);
			background: linear-gradient(var(--item-button-active-back2), var(--item-button-active-back1));
			box-shadow: 0px 1px 2px var(--item-button-active-shadow);
		}
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		transition: all 0.5s ease;
	}
	&.item-list-summary {
		justify-content: flex-start;
		.item-button {
			flex-grow: 1;
			flex-shrink: 0;
			width: auto;
			padding-left: 10px;
		}
	}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
	.item-list {
		.item-button {
			max-width: 250px;
		}
		&.item-list-summary {
			.item-button {
				max-width: 450px;
			}
			&.button-link-more {
				max-width: 150px;
			}
		}
	}
}

.zekr-benefits-container {
	.zekr-body {
		white-space: pre-line;
		text-align: initial;
	}
}
.zekr-container {
	.zekr-body-container {
		text-align: center;
		padding: 10px;

		@media only screen and (max-width: 600px) {
			padding: 0;
		}

		.zekr-body {
			margin-bottom: 20px;
			white-space: pre-line;
			.zekr-body-wrapper {
				.zekr-body-text {
					line-height: 2.4;
				}

				.zekr-body-alternative {
					margin-top: 10px;
					margin-bottom: 10px;
				}
				// TODO: on the same line
				// @media only screen and (min-width: 600px) {
				// 	display: flex;
				// 	justify-content: space-around;
				// 	> div {
				// 		width: 50%;
				// 	}
				// }
			}

			.zekr-body-line {
				margin: 30px auto;

				width: 65%;
				color: var(--zekr-body-line-color);
				opacity: 1;
				background: none;
			}
		}
		img {
			max-width: 100%;
		}
	}
	.zekr-source {
		font-size: 0.8em;
		padding: 5px 10px 10px;
		margin: 2px 0 5px;
	}
}
.zekr-line {
	border: 1px solid transparent;
	border-image: url('/assets-offline/images/line-seperator.svg') 34 40 /* slice */ /0px 40px 20px 40px /* width */ /0 0 10px 0
		/* outset */ round;
}
.zekr-body-play-buttons {
	text-align: center;
	background: var(--zekr-body-play-buttons-back);
	box-shadow: 0px 0px 4px var(--zekr-body-play-buttons-shadow);
	border-radius: 3px;
	padding: 5px 10px;

	position: sticky;
	bottom: -1px;

	max-width: 500px;
	margin: 0 auto;
	margin-bottom: 10px;
	transition: 0.3s;

	img {
		margin: 0 15px 0;
	}

	&.position-sticky {
		box-shadow: 0px -1px 4px var(--zekr-body-play-buttons-sticky-shadow);
	}
}

/*
-----------------
-----------------
*/
.hidden {
	display: none;
}
.hidden-xs {
	display: block;
}
.visible-xs {
	display: none;
}
.hidden-xs-inline {
	display: inline;
}
.visible-xs-inline {
	display: none;
}
/*
-----------------
-----------------
*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	body {
		padding: 0;
	}

	html,
	body {
		height: 100%;
		min-height: 100%;
	}

	#root {
		// overflow: auto;
		padding: 0 12px 0;
	}
	.hidden-xs {
		display: none;
	}
	.visible-xs {
		display: block;
	}
	.hidden-xs-inline {
		display: none;
	}
	.visible-xs-inline {
		display: inline;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
