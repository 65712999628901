.main-footer {
	font-size: 0.6em;
	color: gray !important;
	text-align: center;
	padding: 5px;

	a,
	div {
		color: gray !important;
	}
}
